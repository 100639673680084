import {
  ChevronDownIcon,
  ChevronUpIcon,
  InfoOutlineIcon
} from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Skeleton,
  Spinner,
  Text,
  Tooltip
} from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { useToggle } from 'react-use';

import { TradeRouting } from '@app/components/Swap/components/TradeRouting';
import { toOptionalFixed } from '@app/helpers/format';
import { getCurrentTrade } from '@app/hooks/swap/useTokensRate';
import { Token } from '@app/types/token';
import { USTrade } from '@app/types/trade';

import s from './SwapInfo.module.scss';
import { useSwapSettings } from '@app/hooks/swap/useSwapSettings';
import { TokensPrice } from '@app/components/Swap/components/TokensPrice';

interface Props {
  pairAddress: string | null;
  rate: string | undefined;
  isSwapAvailable: boolean;
  isSwapAvailabilityLoading: boolean;
  isLoadingSwapOut: boolean;
  swapOut: string | undefined;
  showInfo?: boolean;
  state: {
    from: {
      token: Token;
      amount: string;
    };
    to: {
      token: Token;
      amount: string;
    };
    selectedTrade: USTrade | undefined;
  };
  trades: USTrade[] | undefined;
  setSelectedTrade: (trade: USTrade) => void;
}

export const SwapInfo: FC<Props> = ({
  rate,
  isLoadingSwapOut,
  state,
  swapOut,
  showInfo,
  trades,
  setSelectedTrade
}) => {
  const [isOpen, toggleOpen] = useToggle(true);

  const { settings } = useSwapSettings();
  const currentTrade = getCurrentTrade(state.selectedTrade, trades);
  const priceImpact = currentTrade?.priceImpact.toFixed();

  function renderInfoRow(
    label: string,
    value: string | number | undefined,
    loading?: boolean,
    tooltip?: ReactNode
  ) {
    return (
      <HStack justifyContent="space-between" mb={2}>
        <Text className={s.rowLabel}>{label}</Text>
        {loading ? (
          <Skeleton
            height="16px"
            width="64px"
            startColor="neutral.700"
            endColor="neutral.800"
            mb="2px"
          />
        ) : tooltip ? (
          <Tooltip
            label={<div className={s.tooltip}>{tooltip}</div>}
            placement="right"
            portalProps={{ appendToParentPortal: true }}
            zIndex={1000}
          >
            <Text className={s.rowValue}>{value}</Text>
          </Tooltip>
        ) : (
          <Text className={s.rowValue}>{value}</Text>
        )}
      </HStack>
    );
  }

  if (isLoadingSwapOut) {
    return (
      <Box bg="transparent" width="100%" p={2} mt="8px">
        <HStack w="100%" justify="space-between">
          <Text color="neutral.300">Processing...</Text>
          <Spinner width={5} height={5} color="green.2" />
        </HStack>
      </Box>
    );
  }

  if (+state.from.amount > 0 && rate) {
    return (
      <Box bg="transparent" width="100%">
        <HStack justifyContent="space-between">
          <HStack>
            <Text className={s.rowValue}>
              1 {state.from.token.symbol} = {Number(rate)}{' '}
              {state.to.token.symbol}
            </Text>
            <TokensPrice
              value={rate}
              tokenAddress={state.to.token.contractAddress}
              symbol={state.to.token.symbol}
            />
          </HStack>
        </HStack>
        {isOpen && (
          <Box mt={4} borderTop="1px solid gray.500">
            {renderInfoRow(
              'Price impact',
              `${priceImpact ? `${toOptionalFixed(+priceImpact, 2)}%` : 'n/a'}`,
              false,
              <div className={s.tooltipBody}>The impact on market price</div>
            )}
            {renderInfoRow(
              'Max slippage',
              `${settings?.slippage}%`,
              false,
              <>
                <div className={s.tooltipTitle}>
                  Receive at least {swapOut} {state.to.token.symbol}
                </div>
                <div className={s.tooltipBody}>
                  This is the minimum amount you are guaranteed to receive.
                  Otherwise the transaction will be reverted.
                </div>
              </>
            )}
            {renderInfoRow(
              'Fees',
              `0.01%`,
              false,
              <>
                <div className={s.tooltipRow}>
                  <span className={s.tooltipBody}>CORE Network</span>
                  <span className={s.tooltipTitle}>$0.001</span>
                </div>
                <div className={s.tooltipRow}>
                  <span className={s.tooltipBody}>Token Pair Fee</span>
                  <span className={s.tooltipTitle}>$0.001</span>
                </div>
                <div className={s.tooltipBody}>
                  [PLACEHOLDER] Some additional text to elaborate on how fees
                  are calculated.
                </div>
              </>
            )}

            <TradeRouting
              data={trades}
              from={state.from}
              to={state.to}
              setSelectedTrade={setSelectedTrade}
              selectedTrade={state.selectedTrade}
            />
          </Box>
        )}
      </Box>
    );
  }

  return null;
};
