import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Icon } from '@app/components/Icon';

interface Props {
  onClick: () => void;
}

export const SwapButton: FC<Props> = ({ onClick }) => {
  return (
    <Box position="relative">
      <IconButton
        aria-label="flip button"
        onClick={onClick}
        color="white"
        _hover={{
          color: 'green.1'
        }}
      >
        <Icon name="swap" />
      </IconButton>
    </Box>
  );
};
