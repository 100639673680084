import { useCallback, useMemo, useState } from 'react';

import { useSwapAvailablity } from '@app/hooks/swap/useSwapAvailablity';
import { useTokensRate } from '@app/hooks/swap/useTokensRate';
import { useTrade } from '@app/hooks/swap/useTrade';
import { Token } from '@app/types/token';
import { USTrade } from '@app/types/trade';

export function useSwapTokens(initialTokensData: { from: Token; to: Token }) {
  const [state, setState] = useState<{
    from: {
      token: Token;
      amount: string;
    };
    to: {
      token: Token;
      amount: string;
    };
    selectedTrade: USTrade | undefined;
  }>({
    from: {
      token: initialTokensData.from,
      amount: ''
    },
    to: {
      token: initialTokensData.to,
      amount: ''
    },
    selectedTrade: undefined
  });

  const { trades, refetch: refetchTrades } = useTrade(state.from, state.to);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      ...prev,
      from: {
        ...prev.from,
        amount: ''
      },
      to: {
        ...prev.to,
        amount: ''
      }
    }));

    refetchTrades();
  }, [refetchTrades]);

  const handleFromTokenChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      from: {
        ...prev.from,
        amount: val
      }
    }));
  }, []);

  const handleFromTokenSelect = useCallback((token: Token) => {
    setState(prev => ({
      ...prev,
      from: {
        ...prev.from,
        token
      },
      to:
        token.contractAddress === prev.to.token.contractAddress
          ? { ...prev.from }
          : { ...prev.to }
    }));
  }, []);

  const handleToTokenSelect = useCallback((token: Token) => {
    setState(prev => ({
      ...prev,
      to: {
        ...prev.to,
        token
      },
      from:
        token.contractAddress === prev.from.token.contractAddress
          ? { ...prev.to, amount: prev.from.amount }
          : { ...prev.from }
    }));
  }, []);

  const handleFlip = useCallback(() => {
    setState(prev => ({
      ...prev,
      from: { ...prev.to, amount: prev.from.amount },
      to: { ...prev.from, amount: '' }
    }));
  }, []);

  const setSelectedTrade = useCallback((val: USTrade | undefined) => {
    setState(prev => ({
      ...prev,
      selectedTrade: val
    }));
  }, []);

  const {
    isSwapAvailable,
    isLoading: isSwapAvailabilityLoading,
    pairAddress
  } = useSwapAvailablity(state.from.token, state.to.token, trades);

  const { swapOut, rate, isLoading, fetchRates } = useTokensRate(
    state.from.token,
    state.to.token,
    state.from.amount,
    isSwapAvailable,
    trades,
    state.selectedTrade
  );

  return {
    trades,
    state,
    setState,
    handleFromTokenChange,
    handleFromTokenSelect,
    handleToTokenSelect,
    handleFlip,
    swapOut,
    isLoadingSwapOut: isLoading,
    rate,
    isSwapAvailable,
    isSwapAvailabilityLoading,
    fetchRates,
    resetStateValues,
    pairAddress,
    setSelectedTrade
  };
}
