import { Spinner, Center, Box, Flex } from '@chakra-ui/react';
import { NextPage } from 'next';
import React, { useMemo } from 'react';

import MainLayout from '@app/components/MainLayout';
import { Swap } from '@app/components/Swap';
import { config } from '@app/config';
import { TOKENS_WHITELIST } from '@app/constants/tokensWhitelist';
import { getWhitelistedCoinIcon } from '@app/hooks/swap/helpers';
import { APP_NAME } from '@app/constants/common';

// @ts-ignore
export const SwapPage: NextPage = () => {
  const initialTokensData = useMemo(() => {
    const from = TOKENS_WHITELIST.find(
      t => t.contractAddress === config.defaultTokens.from
    )!;

    const to = TOKENS_WHITELIST.find(
      t => t.contractAddress === config.defaultTokens.to
    )!;

    return {
      from: {
        ...from,
        logoUri: getWhitelistedCoinIcon(from)
      },
      to: {
        ...to,
        logoUri: getWhitelistedCoinIcon(to)
      }
    };
  }, []);

  return (
    <MainLayout metadata={{ title: `Swap | ${APP_NAME}` }}>
      {initialTokensData ? (
        <Swap initialTokensData={initialTokensData} />
      ) : (
        <Flex
          w="100%"
          h="100%"
          minHeight="calc(100dvh - 200px)"
          justifyContent="center"
        >
          <Center>
            <Spinner color="orange" width={8} height={8} />
          </Center>
        </Flex>
      )}
    </MainLayout>
  );
};

export default SwapPage;
